import './Footer.scss';

import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-contact-info">
        <h3>Say Hello</h3>
        <a
          href="tel:&#052;&#052;&#051;&#057;&#051;&#055;&#051;&#051;&#056;&#057;"
          className="call-us"
        >
          <span className="label">
            &#052;&#052;&#051;-&#057;&#051;&#055;-&#051;&#051;&#056;&#057;
          </span>
        </a>
        <br />
        <a
          href="mailto:&#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;"
          className="email-us"
        >
          <span className="label">
            &#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;
          </span>
        </a>
        <br />
        <a href="http://twitter.com/friendsoftheweb" className="tweet-at-us">
          <span className="label">@friendsoftheweb</span>
        </a>
      </div>

      <div className="newsletter-sign-up">
        <h3>Our Friendly Newsletter</h3>
        <form
          action="https://friendsoftheweb.createsend.com/t/r/s/yhujlld/"
          method="post"
          id="subForm"
        >
          <input
            type="text"
            placeholder="Email Address"
            name="cm-yhujlld-yhujlld"
            id="yhujlld-yhujlld"
          />
          <input type="submit" id="submit" alt="Sign Up" value="Sign Up" />
        </form>
        <a href="http://madewithloveinbaltimore.org/" className="our-friends">
          Made with <img src="/images/heart.png" alt="Love" /> in Baltimore, MD
        </a>
      </div>
    </footer>
  );
};

export default Footer;
