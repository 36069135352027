import './Header.scss';

import React from 'react';
import { Link } from 'gatsby';

import Logo from 'components/Logo';

interface Props {
  tagline: string;
}

const Header: React.FC<Props> = (props) => {
  return (
    <>
      <header>
        <Link to="/" className="header-logo">
          <Logo />
        </Link>
        <div className="header-tagline">
          <strong>
            <Link to="/">Friends of The Web</Link>
          </strong>{' '}
          {props.tagline}
        </div>

        <nav className="navigation">
          <Link className="about-link" activeStyle={{ display: 'none' }} to="/">
            About Us
          </Link>
          <Link
            className="work-link"
            activeStyle={{ display: 'none' }}
            to="/work/"
          >
            View Selected Work
          </Link>
          <Link
            className="contact-link"
            activeStyle={{ display: 'none' }}
            to="/hire-us/"
          >
            Contact Us
          </Link>
        </nav>
      </header>

      <hr />
    </>
  );
};

export default Header;
