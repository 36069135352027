import React from 'react';

import BaseLayout from './Base';

import Header from 'components/Header';
import Footer from 'components/footer';

interface Props {
  children: React.ReactNode;
  title: string;
  tagline: string;
}

/**
 * Standard layout for the basic pages including the header and footer.
 */
const LayoutStandard: React.FC<Props> = (props) => {
  return (
    <BaseLayout title={props.title}>
      <Header tagline={props.tagline} />
      {props.children}
      <Footer />
    </BaseLayout>
  );
};

export default LayoutStandard;
