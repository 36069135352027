import './internships.scss';

import React from 'react';

import Layout from 'components/layouts/Standard';

const InternshipsPage: React.FC = () => {
  return (
    <Layout
      title="Internships &mdash; Friends of The Web"
      tagline="works with students studying Graphic Design, UX, or a related field. Our part-time employees work alongside our team of designers and developers to create websites, mobile applications, and other digital products."
    >
      <div className="internships-content">
        <h2>
          There are no open internship positions at the moment. Please check
          back at a later date.
        </h2>
      </div>
    </Layout>
  );
};

export default InternshipsPage;
